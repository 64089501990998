@import 'components/colors';


/**************************************
     -   Tabelle Ausrüstungsverleih
***************************************/
.table-expand {
  margin-top: 5rem;

  td {
    color: $brand-dark-gray;
  }

  tr {
    border: 1px solid $brand-light-gray;
	border-bottom: 1px solid $brand-primary;
  }

  .text-right {
    padding-right: 1rem;
  }
  
  th > span {
    font-size: .9rem;
	font-weight: 300;
  }
}

tbody {
	.table-expand-row {
		cursor: pointer;
	}
}

.table-expand-row {

  &.is-active {
    .expand-icon::after {
      content: '-';
    }
  }

  .expand-icon::after {
   color: $brand-primary;
   font-size: 1.5rem;
   content: '+';
    float: right;
  }
}

// this is where the magic happens
.table-expand-row-content {
  display: none;
}

.table-expand-row-content {
  &.is-active {
    display: table-row;
    animation: fadeIn ease-in 1;
    animation-fill-mode: both;
    animation-duration: 0.5s;
  }
}

.table-expand-row-nested {
  background-color: $brand-primary;
}

.table-expand-row-nested > p {
  color: $brand-white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.card-border-primary {
	background-color: darken($brand-primary, 20%);
	color: $brand-white;
	padding: 6.25rem 1rem;
	text-align: center;
	
	h4 {
		font-weight: 600;
	}
	
	.marketing--title {
		font-size: 2rem;
		color: $brand-white;
		margin-bottom: 1.5rem;
		text-transform: uppercase;
	}
	
	a {
		color: $brand-white;
		text-decoration: none;
	}
	
	a:hover, a:focus, a:active {
		color: $brand-white;
		text-decoration: none;
	}
	
	&::after {
		position: relative;
		padding: 55px 0;
		background-image: url("/assets/images/backgrounds/bg-overlay-grey.svg");
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: 50%;
	}
	
	&.logo-gray {
		/*padding: 55px 0;*/
		background-image: url('/assets/images/backgrounds/bg-overlay-grey.svg');
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: 15%;
	}
}

.card-border-secondary {
	position: relative;
	background-color: darken($brand-dark-gray, 0%);
	color: $brand-white;
	padding: 6.25rem 1rem;
	border: none;
	text-align: center;
	
	h4 {
		font-weight: 600;
	}
	
	.marketing--title {
		font-size: 2rem;
		margin-bottom: 1.5rem;
		text-transform: uppercase;
	}
	
	a {
		color: $brand-white;
		text-decoration: none;
	}
	
	a:hover, a:focus, a:active {
		color: $brand-white;
		text-decoration: none;
	}
	
}

.card-border-gray {
	background-color: $brand-dark-gray;
	/*background-color: darken($brand-success, 20%);*/
	color: $brand-white;
	padding: 6.25rem 1rem;
	text-align: center;
	
	h4 {
		font-weight: 600;
	}
	
	.marketing--title {
		font-size: 2rem;
		color: $brand-primary;
		margin-bottom: 1.5rem;
		text-transform: uppercase;
	}
	
	a {
		color: $brand-white;
		text-decoration: none;
	}
	
	a:hover, a:focus, a:active {
		color: $brand-white;
		text-decoration: none;
	}
	
	&.logo-gray {
		/*padding: 55px 0;*/
		background-image: url('/assets/images/backgrounds/bg-overlay-grey.svg');
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: 15%;
	}
}

/**************************************
     -   Tabelle Ausrüstungsverleih
***************************************/
$responsive-card-table-bottom-gap: 1.5rem;
$responsive-card-table-border: 1px solid $brand-middle-gray;
$responsive-card-table-stack-point: 768px;
$responsive-card-table-label-bg: $brand-light-gray;

.responsive-card-table {
	border-collapse: collapse;

	&.unstriped tbody {
		background-color: transparent;
	}

	th {
		background-color: $responsive-card-table-label-bg;
		font-size: 1.2rem;
	}

	tr,
	th,
	td {
		border: $responsive-card-table-border;
	}

	@media screen and (max-width: $responsive-card-table-stack-point) {
		thead tr {
			position: absolute;
			top: -9999em;
			left: -9999em;
		}

		tr {
			border: $responsive-card-table-border;
			display: block;
		}

		tr + tr {
			margin-top: $responsive-card-table-bottom-gap;
		}
		/* Get table cells to act like rows */

		td {
			border: none;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border-bottom: $responsive-card-table-border;
			padding-left: 50%;
			position: relative;

			&:before {
				content: attr(data-label);
				display: inline-block;
				font-weight: bold;
				line-height: 1.5;
				margin-left: -100%;
				width: 100%;
				position: relative;
				z-index: 1;
			}

			&::after {
				content: '';
				position: absolute;
				background: $responsive-card-table-label-bg;
				width: 48%;
				height: 95%;
				left: 1px;
				top: 1px;
				z-index: 0;
			}
		}
	}
}

