@import 'components/colors';


/**************************************
     -   Google Fonts local
***************************************/
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/raleway-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Raleway'), local('Raleway-Regular'),
       url('/assets/fonts/raleway-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/raleway-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/raleway-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/raleway-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/raleway-v12-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/raleway-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
       url('/assets/fonts/raleway-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/raleway-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/raleway-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/raleway-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/raleway-v12-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/raleway-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Raleway Bold'), local('Raleway-Bold'),
       url('/assets/fonts/raleway-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/raleway-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/raleway-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/raleway-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/raleway-v12-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}



/**************************************
     -   Fonts in use
***************************************/
$raleway: 'Raleway', sans-serif;


/**************************************
     -   Texte Allgemein
***************************************/
h2.hyphens, h3.hyphens, h4.hyphens, h5.hyphens, h6.hyphens, p.hyphens {
   -moz-hyphens: auto;
   -o-hyphens: auto;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto; 
}

h1, .h1 {
    font-size: 72px !important;
	font-weight: 700 !important;
    line-height: 1 !important;
    margin-bottom: 48px !important;
    text-transform: uppercase !important;
	letter-spacing: -2px !important;
	
	@media (max-width: 1199px) {
		font-size: 60px !important;
		margin-bottom: 36px !important;
	}

	@media (max-width: 639px) {
		font-size: 40px !important;
		margin-bottom: 36px !important;
	}
}


h3, .h3 {
	font-size: 45px !important;
	font-weight: 700 !important;
    line-height: 1.03 !important;
    margin-bottom: 24px !important;
	text-transform: uppercase !important;
	letter-spacing: .2px !important;
	
	@media (max-width: 1199px) {
		font-size: 36px !important;
		/*margin-bottom: 36px !important;*/
	}

	@media (max-width: 639px) {
		font-size: 28px !important;
		/*margin-bottom: 36px !important;*/
	}
}

h6, .h6 {
	font-size: 1.2rem !important;
	font-weight: 400 !important;
	line-height: 1 !important;
	margin-bottom: 0px !important;
	text-transform: uppercase !important;
	letter-spacing: .5px !important;
}

.fotografen {
	width: 160px;
	height: 160px;
	border-radius: 80px 0 0 80px;
	box-shadow: 0 0 0 8px $brand-light-gray;
	margin: 15px 0;
}

.top-20 {
	padding-top: 20px;
}

.top-40 {
	padding-top: 40px;
}

.top-60 {
	padding-top: 60px;
}

.top-100 {
	padding-top: 100px;
}

.bottom-20 {
	padding-bottom: 20px;
}

.bottom-60 {
	padding-bottom: 60px;
}

.bottom-100 {
	padding-bottom: 100px;
}



/**************************************
     -   Allgemein
***************************************/
a.skipping {
    position: fixed;
    left: -10000px;
    top: -10000px;
    z-index: 10000;
    font-size: 1rem;
    padding: 5px;
	
	&:focus {
		top: 0;
		left: 0;
	}	
	
	&.bg-skipping {
		color: $brand-white;
		background-color: $brand-primary;
	}
}

section {
	&#first-maincontent, &#main-second, &#partner, &#products, &#rent, &#dsgvo, &#impressum, &#fewo, &#agb, &#mailer {
		padding-top: 100px;
		padding-bottom: 100px;
	}
}

#fewo {
	
	.accordion {
		margin-bottom: 0 !important;
	}
}

.no-padding {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

sup {
	color: darken($brand-primary, 20%);
}

.no-margin {
    margin: 0 !important;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

#first-maincontent {
	position: relative;
	background-color: $brand-light-gray;
}

p a {
	color: $brand-primary;
	text-decoration: none;
}

p a:hover, p a:focus {
	color: $brand-primary;
	text-decoration: underline;
}

.full-height_wrapper {
    width: 100vw;
    height: 100%;
    overflow: hidden;
}

.min-height-40 {
    min-height: 40vh;
    max-width: 100%;
}

.card-xxx {
    z-index: 1 !important;
    outline: none !important;
    min-height: 260px !important;
    border-right: 1px solid #c8c8c8 !important;
    padding: 15px !important;
}

.min-height-60 {
    min-height: 60vh;
    max-width: 100%;
	/* margin-top: 40px;
	margin-bottom: 40px; */
}

body {
    color: $brand-dark-gray !important;

	.pad {
		padding: 8px 20px;
	}
		
	&.overflow {
		overflow: hidden;
	}
	.display-inline {
		display: inline;
		font-size: .975rem;
	}
	.color-schett {
		color: $brand-primary;
	}
	.color-white {
		color: $brand-white;
		background-color: rgba($brand-primary, 1.0);
		
		
		> a {
			color: $brand-white;
			text-decoration: none;
		}

		> a:hover, a:focus {
			color: $brand-white;
			text-decoration: underline;
		}
	}
	
	.XXmSlider {
		position: relative;
		top: 0px;
		left: 0px;
		overflow: hidden;
		height: 100%;
		width: 100%;
		-webkit-transform-origin: 50% 100%;
		-ms-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		transition-property: background, opacity, transform, filter, background, opacity, transform, filter;
		transition-property: background, opacity, transform, filter;
		transition-duration: 0.33s;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		
		.XXmSliderPane {
			position: relative;
			top: 0;
			left: 0;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			background-color: #fff;
			outline: 0;
			z-index: 10;
			overflow-x: hidden;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			box-shadow: 0 1px 5px rgba(0,0,0,.3);
			height: 100%;
			width: 100%;
			transition-duration: .33s;
			-webkit-transform: translateX(0);
			-ms-transform: translateX(0);
			transform: translateX(0);
		}
	}
}


::selection {
    color: $brand-white;
	background: $brand-primary;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	
	@media only screen and (max-width: 767px) {
		width: 5px;
		height: 5px;
	}
}

::-webkit-scrollbar-thumb {
	transition-duration: .33s;
	background-color: $brand-primary;
}

::-webkit-scrollbar-track {
	background-color: $brand-light-gray;
}

.row-line-medium {
    border-top: none;
}

@media only screen and (max-width: 1023px) {
	.row-line-medium {
		display: block;
		clear: both;
		border-top: 1px solid #c8c8c8;
	}
}

#fewo-mailer {

	.has-tip {
		border-bottom: 0;
	}
}


/**************************************
     -   Bereich WALL
***************************************/
.contentWall {
	padding: 0;
	padding-top: 2.5px;
	padding-bottom: 2.5px;

	.itemContainer-li {
		border-right: 2.5px solid transparent;
		border-top: 2.5px solid transparent;
		border-bottom: 2.5px solid transparent;
		
		@media only screen and (max-width: 1023px) {
			border-bottom: 2.5px solid transparent;
			border-right: 0px solid transparent;
		}
	}
	
	.itemContainer-re {
		border-left: 2.5px solid transparent;
		border-top: 2.5px solid transparent;
		border-bottom: 2.5px solid transparent;
		
		@media only screen and (max-width: 1023px) {
			border-top: 2.5px solid transparent;
			border-left: 0px solid transparent;
		}
	}

	.wallImg {
		min-height: 480px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	
	@media screen and (min-width: 1024px) {
		.teaser .teaser__content .btn-wall {
			opacity: 0;
			transition: all 250ms ease-in-out;
		}
	}
	
	.mt15 {
		margin-top: 15px;
	}
	
	.btn-wall-md {
		padding: 12px 30px;
		font-size: 19px;
	}
	
	.btn-wall {
		position: relative;
		display: inline-block;
		min-width: 320px;
		margin-bottom: 0;
		text-align: center;
		vertical-align: middle;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		cursor: pointer;
		background-image: none;
		border: none;
		white-space: nowrap;
		padding: 14px 23px;
		font-size: 18px;
		line-height: 1.42857143;
		border-radius: 10px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		
		&.btn-wall-white {
			background: $brand-white;
			color: $brand-black;
		}
		
		&:hover {
			background: $brand-white;
			color: $brand-primary;
		}
		
		&::before {
			content: '';
			display: block;
			position: absolute;
			border: 1px solid;
			border-radius: 6px;
			top: 4px;
			left: 4px;
			right: 4px;
			bottom: 4px;
			opacity: .5;
		}
	}
	
	
	.teaser__hover-overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		background: linear-gradient(to bottom,transparent 0,$brand-black 100%);
		transition: opacity 250ms;
	}
	
	.isClickable:hover .teaser__hover-overlay {
		opacity: .75;
	}	
	
	.isClickable:hover .btn-wall {
		opacity: 1;
	}	
	
	.isClickable:hover .teaser__content {
		-webkit-transform: translateY(-29%);
		-ms-transform: translateY(-29%);
		transform: translateY(-29%);
		transition: all 250ms ease-in-out;
	}
	
	.isClickable:hover .teaser__subline {
		color: $brand-primary;
		transition: all 250ms ease-in-out;
	}
	
	.teaser {
		position: relative;
		color: $brand-white;
		
		&::after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba($brand-black,.25);
			z-index: 0;
			opacity: 1;
			transition: opacity 250ms;
		}
		
		.teaser__content {
			position: absolute;
			top: 40%;
			left: 16px;
			right: 16px;
			z-index: 1;
			transition: all 250ms ease-in-out;
			
			@media screen and (min-width: 768px) {
				left: 30px;
				right: 30px;
			}
		}
		
		.teaser__border {
			position: absolute;
			top: 10px;
			right: 10px;
			bottom: 10px;
			left: 10px;
			border: 1px solid rgba($brand-white, .5);
			z-index: 1;
			
			@media screen and (min-width: 768px) {
				top: 20px;
				right: 20px;
				bottom: 20px;
				left: 20px;
			}
		}
		
		.teaser__title {
			font-size: 1.5rem;
			color: $brand-white;
			
			@media screen and (min-width: 1200px) {
				font-size: 2rem !important;
			}
		}
		
		.teaser__subline {
			margin-bottom: 15px;
			color: $brand-white;
			font-weight: 400;
			transition: all 250ms ease-in-out;
		}
	}

}


/**************************************
     -   Header Image
***************************************/
#header-img-overlay {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
    /* background: url("/assets/images/transparent.png") repeat; */
	background: -webkit-gradient(linear, left bottom, left top, color-stop(30%, transparent), to($brand-dark-gray));
	background: linear-gradient(to top, transparent 40%, rgba($brand-white, 0.9) 100%);
	z-index: 9;
}

.header-img {
	background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
	background-color: $brand-light-gray;
    height: 100vh;
	overflow: hidden;
}

.heading {
    padding: 0 25px;
	z-index: 10;
	top: 12%;
	opacity: 0;
	
	&.heading-animation {
		opacity: 1;
		transition-property: -webkit-transform;
		transition-property: transform;
		transition-duration: .975s;
		-webkit-transform: translateY(50%);
		-ms-transform: translateY(50%);
		transform: translateY(50%);
		-webkit-transition: all 0.75s ease;
		-moz-transition: all 0.75s ease;
		-o-transition: all 0.75s ease;
		transition: all 0.75s ease;
	}
	
	&.heading--center {
		width: 100%;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		margin: 5% 0;
		text-align: center;
	}
	
	.intro {
		font-family: $raleway;
		font-size: 1.5rem;
		line-height: 1;
		margin-top: 25px;
		margin-bottom: 40px;
		/* text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); */
		
		@media (max-width: 1199px) and (min-width: 640px) {
			font-size: 1.3rem;
			line-height: 1;
			margin-bottom: 30px;
		}

		@media (max-width: 639px) {
			font-size: 1rem;
			line-height: 1;
			margin-bottom: 30px;
		}
	}

}

.heading__title {
	margin-top: 0;
	/* text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); */
}

.heading__subtitle {
	margin-bottom: 0;
	padding-bottom: 10px;
	/* text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.4); */
}

.heading__divider {
	height: 3px;
	margin-top: 10px;
	margin-bottom: 40px;
	width: 150px;
	background-color: $brand-primary;
}


/**************************************
     -   First Content
***************************************/
.flex-item-center {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.justify-center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flexbox {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.column-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;

	@media only screen and (max-width: 1199px) {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
	}
}

.column-2 .heading__divider--inline {
    margin: 22px 0;
}

.heading__divider--inline {
    display: inline-block;
    background-color: transparent;
    position: relative;
    width: 100%;
	margin: 15px 0 30px 0;
}

.heading__divider--inline::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    height: 3px;
    background-color: $brand-primary;
}

.content__wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.heading--center {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 25px 0;
    text-align: center;
}


/**************************************
     -   Scroll TOP
***************************************/
#scroll_top {
  	background: rgba($brand-dark-gray,.85);
	position: fixed;
    right: 0;
    margin-right: 25px;
	bottom: 25px; /* position of circle */
	display: block;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	display: none;
	width: 60px;
	height: 30px;
	-webkit-box-shadow: 0 0 15px 5px rgba($brand-white,.3);
	box-shadow: 0 0 15px 5px rgba($brand-white,.3);
	-webkit-transition: all 0.4s linear;
	-moz-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
	z-index: 99;
	
	&:hover {
		background: rgba($brand-primary,1);
	}
	
	p {
		font-size: .7rem;
		margin: 0;
		color: $brand-white;
		position: relative;
		top: 7px;  /* startposition of the arrow */
		text-align: center;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		-ms-transition: all 0.4s ease;
		 -o-transition: all 0.4s ease;
		 transition: all 0.4s ease;
		 
		&:hover {
			top: 7px;  /* endposition of the arrow */
		}
	}
}


/**************************************
     -   Scroll Down
***************************************/
.scroll-icon {
	z-index: 99;
	position: absolute;
	bottom: 80px;
    left: calc(50% - 18px);
}

@-webkit-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 60%;
	}
	50% {
	opacity: 0;
	top: 60%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}

@-moz-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 60%;
	}
	50% {
	opacity: 0;
	top: 60%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}

@keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 60%;
	}
	50% {
	opacity: 0;
	top: 60%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}

 .scroll-btn {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
}

.scroll-btn > * {
	display: inline-block;
	line-height: 18px;
	font-size: 13px;
	font-weight: normal;
	color: $brand-white;
	letter-spacing: 2px;
}

.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
	color: $brand-white;
}

.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.scroll-btn .mouse {
	position: relative;
	display: block;
	width: 30px;
	height: 50px;
	margin: 0 auto 20px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 3px solid $brand-white;
	border-radius: 0 0 20px 20px;
	background: rgba($brand-black,.25);
}

.scroll-btn .mouse > * {
	position: absolute;
	z-index: 100;
	display: block;
	top: 29%;
	left: 50%;
	width: 3px;
	height: 10px;
	margin: -2px 0 0 -2px;
	background: $brand-white;
	border-radius: 5px;
	-webkit-animation: ani-mouse 2.0s linear infinite;
	-moz-animation: ani-mouse 2.0s linear infinite;
	animation: ani-mouse 2.0s linear infinite;
}


/**************************************
     -   Swiper Img
***************************************/
.swiper-img {
	position: relative;
	/* min-height: 65vh; */
	height: 650px;
	background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
	background-color: $brand-light-gray;
		
	@media screen and (max-width: 1199px) and (orientation: portrait) {
		height: 400px;
	}
		
	@media screen and (max-width: 1199px) and (orientation: landscape) {
		height: 400px;
	}
	
	@media screen and (max-width: 639px) and (orientation: portrait) {
		height: 300px;
	}
	
	@media screen and (max-width: 639px) and (orientation: landscape) {
		height: 300px;
	}

	@include breakpoint(xlarge) {
		&::after {
			content: '';
			position: absolute;
			right: -1px;
			top: 0;
			bottom: 0;
			z-index: 110;
			width: 0px;
			height: 0px;
			-webkit-transform:rotate(360deg);
			border-style: solid;
			border-width: 0 0 650px 120px;
			border-color: transparent transparent darken($brand-primary, 0%) transparent;

			@media screen and (max-width: 1199px) and (orientation: portrait) {
				border-width: 0 0 400px 120px;
			}
			
			@media screen and (max-width: 1199px) and (orientation: landscape) {
				border-width: 0 0 400px 120px;
			}
			
		}
	}
}

.swiper-text {
	position: relative;
	background-color: darken($brand-dark-gray, 10%);
	/* min-height: 65vh; */
	height: 650px;

	@media screen and (max-width: 1199px) and (orientation: portrait) {
		min-height: 40vh;
	}
	
	@media screen and (max-width: 1199px) and (orientation: landscape) {
		min-height: 40vh;
	}

	@media screen and (max-width: 639px) and (orientation: portrait) {
		min-height: 40vh;
	}
	
	@media screen and (max-width: 639) and (orientation: landscape) {
		min-height: 40vh;
	}
	
	.heading__title {
		font-weight: 600 !important;
		padding: 0 40px 5px 40px;
	}
	
	.heading__subtitle {
		padding: 0 40px;
		margin-top: 60px !important;
		margin-bottom: 10px !important;
	}
	
	.heading__divider {
		height: 3px;
		margin: 25px 0 35px 40px;
		width: 100px;
		background-color: $brand-primary;
	}
	
	p {
		padding: 0 40px 100px 40px;
		font-size: .975rem;
	}
	
	> .v-center {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		left: 0;
		right: 0;
		
		@media screen and (max-width: 1199px) and (orientation: portrait) {
			display: inline;
			position: relative;
		}
		
		@media screen and (max-width: 1199px) and (orientation: landscape) {
			display: inline;
			position: relative;
		}
		
		@media screen and (max-width: 639px) and (orientation: portrait) {
			display: inline;
			position: relative;
		}
		
		@media screen and (max-width: 639px) and (orientation: landscape) {
			display: inline;
			position: relative;
		}
	}
}

.swiper-container-horizontal>.swiper-pagination-bullets, 
.swiper-pagination-custom, .swiper-pagination-fraction {
	bottom: 20px!important;
	left: 60% !important;
    width: 250px !important;
    text-align: left;
	
	@include breakpoint(small down) {
		left: 40px !important;
	}
}

.swiper-pagination-bullet {
	width: 38px !important;
	height: 38px !important;
	opacity: 1 !important;
	border-radius: 10px !important;
	background: rgba(254,254,254,0.5) !important;
	-webkit-transition: height 250ms ease;
	-moz-transition: height 250ms ease;
	-ms-transition: height 250ms ease;
	-o-transition: height 250ms ease;
	transition: height 250ms ease;
}

.swiper-pagination-bullet-active {
	height: 60px !important;
	border-radius: 10px !important;
	background: darken($brand-primary, 10%) !important;
}

.swiper-button-next {
	right: -42px !important;
    width: 40px !important;
    height: 40px !important;
	z-index: 150 !important;
    /* margin: 5px; */
    border-radius: 0 !important;
    background-color: rgba(0,0,0,0.60) !important;
    background-size: 16px 16px !important;
	
	&:hover {
		background-color: $brand-primary !important;
	}
}

.swiper-button-prev {
	left: -40px !important;
    width: 40px !important;
    height: 40px !important;
	z-index: 150 !important;
    border-radius: 0 !important;
    background-color: rgba(0,0,0,0.60) !important;
	background-size: 16px 16px !important;
	
	&:hover {
		background-color: $brand-primary !important;
	}
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    opacity: 0 !important;
}

.swiper-scrollbar {
	background: rgba($brand-primary, .75) !important;
}


/**************************************
     -   Accordion
***************************************/
.accordion {
    background: $brand-white;
	
	.accordion-title {
		position: relative;
		display: block;
		padding: 1.25rem 3.5rem 1.25rem 1.25rem;
		border: 0;
		font-family: $raleway;
		font-weight: 600;
		color: $brand-dark-gray;
		
		@media screen and (min-width: 1200px) {
			line-height: 1.5;
			font-size: 1.5rem;
		}
		
		@media screen and (max-width: 1199px) {
			line-height: 1.3;
			font-size: 1.3rem;
		}
		
		@media screen and (max-width: 639px) {
			line-height: 1.1;
			font-size: 1.1rem;
		}
		
		> a:hover, > a:focus {
			color: $brand-primary;
			background-color: $brand-white;
		}
		
		&::before {
			position: absolute;
			top: 50%;
			right: 2rem;
			margin-top: -2.2rem;
			font-size: 2.5rem;
			
			@media screen and (max-width: 1199px) {
				margin-top: -1.5rem;
				font-size: 2rem;
			}
			
			@media screen and (max-width: 639px) {
				margin-top: -0.8rem;
				font-size: 1.5rem;
			}
		}
					
	}
	
	.accordion-item {
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-bottom: 1px solid $brand-primary;
		line-height: 1.7;
		font-size: .975rem;
		font-weight: 400;
		background-color: $brand-light-gray;
		
		&.is-active {
			background-color: $brand-middle-gray;
		}
		
		> a {
			text-decoration: none;
		}
		
		> a:hover, a:focus {
			color: $brand-primary;
			text-decoration: none;
		}
	}
	
	.accordion-content {
		padding: 30px 15px;
		border: 0;
		background-color: $brand-light-gray;
		color: $brand-dark-gray;
		
		& a {
			color: $brand-primary;
		}
	}
	
	.list-check li:before {
		content: "\f00c";
		font-size: 1.2rem;
		font-family: "Font Awesome 5 Free";
		font-weight: 700;
		margin-right: 14px;
		color: $brand-primary;
		display: inline-block;
		speak: none;
		line-height: 1;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-transform: translate(0,0);
		-ms-transform: translate(0,0);
		transform: translate(0,0);
	}
	
	.top-20-accordion {
		padding-top: 20px;
	}
}


/**************************************
     -   Table Card
***************************************/
.responsive-card-table th {
    background-color: $brand-middle-gray !important;
}

.responsive-card-table td::after {
	content: '';
    position: absolute;
    background: $brand-middle-gray !important;
    height: 100% !important;
    left: 1px !important;
    top: 1px !important;
}



/**************************************
     -   Booking Datepicker
***************************************/

input[readonly] {
    background-color: $brand-white !important;
    cursor: text !important;
}

.label-agb {
	margin-left: 20px !important;
    float: left;
    position: relative;
}

.input-agb {
    float: left;
    position: absolute;
    margin-top: 5px !important;
}

.label-dsgvo {
	margin-left: 20px !important;
    float: left;
    position: relative;
}

.input-dsgvo {
    float: left;
    position: absolute;
    margin-top: 5px !important;
}

.datepicker td, .datepicker th {
    width: 36px !important;
    height: 36px !important;
	font-weight: 700;
    font-size: .95rem !important;
}

.date-switch {
	font-weight: 700;
	text-transform: uppercase !important;
}

.xxx-datepicker td.active.day:before, .xxx-datepicker td.active.year:before {
    position: absolute;
    top: 0;
    right: 0;
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 0;
    border-color: transparent $brand-white transparent transparent;
}

.xxx-datepicker td.active.day:after, .xxx-datepicker td.active.year:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 0 10px;
    border-color: transparent transparent transparent $brand-white;
}

.datepicker td.day {
	position: relative;
	background: $brand-white;
	color: $brand-dark-gray !important;
	font-weight: 700;
	border: 1px solid $brand-white;
}

.datepicker td.day:hover {
	background: $brand-primary;
	color: $brand-white !important;
}

.datepicker td.active.day, .datepicker td.active.year {
	position: relative;
	background: $brand-primary !important;
	color: $brand-white !important;
}

.datepicker td.day.disabled {
	opacity: .5;
	background: none;
	background-image: url("../../assets/images/date-not-available.png");
	font-weight: 400;
	cursor: not-allowed;
}

.datepicker td.day.old, .datepicker td.day.new {
	opacity: .0;
	background: $brand-white;
	font-weight: 400;
	cursor: not-allowed;
}

.datepicker-days tr:nth-child(even) {
    background-color: $brand-white;
}


/**************************************
     -   BREADCRUMBS
***************************************/
.breadcrumbWrapper {
    background: $brand-light-gray;
    padding: 0 10px;
	/*border-top: 5px solid $brand-white;*/
	border-bottom: 5px solid $brand-white;
	
	nav {
		padding: 0;
	}
	
	.breadcrumbs {
		background: transparent;
		padding: 25px 0;
		margin-bottom: 0;
		letter-spacing: .05rem;
		
		a {
			color: $brand-primary !important;
		}
		
		li {
			font-size: .8rem;
			text-transform: uppercase;
		}
		
		li:not(:last-child)::after {
			position: relative;
			margin: 0 .75rem;
			opacity: 1;
			content: "¦";
			color: $brand-black;
		}
		
		li:nth-child(1)::after {
			position: relative;
			margin: 0 .5rem;
			opacity: 1;
			content: "";
			color: $brand-black;
		}
		
		li:nth-child(1) {
			padding-left: 15px;
			
			@media screen and (max-width: 639px) {
				display: none;
			}
		}
	}
}


/**************************************
     -   Google Map Overlay
***************************************/
.scrolloff {
	pointer-events: none;
}
#map {
	height: calc(100vh - 450px);
	
	@media screen and (max-width: 1199px) {
		height: calc(100vh - 300px);;
	}
}

/**************************************
     -   Parallax Image
***************************************/
.parallax-img {
	background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
	background-color: $brand-light-gray;
    height: 650px;
	overflow: hidden;
}


/**************************************
     -   Footer
***************************************/
.partnerlist {
    position: relative;
    text-align: center;
    padding: 10px 0;
    /* border-bottom: 5px solid #fff; */
    background: $brand-middle-gray;
	
	ul {
		position: relative;
		display: block;
		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;
		
		li {
			margin: 0;
			padding: 60px 35px;
			display: inline-block;
		}
	}
}

.Arrange {
    box-sizing: border-box;
    margin: 0;
    min-width: 100%;
    padding: 0;
    table-layout: auto;
}

.Arrange-sizeFit, .Arrange-sizeFill {
    padding: 0 15px;
	vertical-align: middle;
	display: table-cell;
}

footer h5 {
	padding-bottom: 25px;
	font-weight: 600;
}

footer a {
	color: #333 !important;
	text-decoration: none;
	
	:hover {
		color: #333 !important;
		text-decoration: underline;
	}
}

.list-style {
	text-align: center;
	padding: 60px 0;
	
	ul {
		list-style: none;
	}
}