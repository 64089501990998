@import 'components/burger';
@import 'components/colors';


/**************************************
     -   Fonts
***************************************/

$raleway: 'Raleway', sans-serif;
$spectral: 'Spectral', serif;


/**************************************
     -   Mixins
***************************************/

@mixin transform($transform) {
  -webkit-transform: $transform;
     -moz-transform: $transform;
      -ms-transform: $transform;
       -o-transform: $transform;
          transform: $transform;
}

@mixin transition($transition) {
  -webkit-transition: #{$transition};
     -moz-transition: #{$transition};
       -o-transition: #{$transition};
          transition: #{$transition};
}

@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: #{$transition-delay};
     -moz-transition-delay: #{$transition-delay};
       -o-transition-delay: #{$transition-delay};
          transition-delay: #{$transition-delay};
}

/**************************************
     -   Burger-Icon
***************************************/

.logo {
    position: fixed;
    z-index: 120;
    width: 250px;
    height: auto;
    top: 0;
    left: 0;
    margin: 30px 0 0 30px;
    background-color: transparent;
	
	@media (max-width: 1199px) {
		margin: 10px 0 0 0;
		-webkit-transform: scale(.60);
		-ms-transform: scale(.60);
		transform: scale(.60);
	}
}

.burger {
    position: fixed;
    width: 70px;
    height: 70px;
    top: 20px;
    right: 20px;
    background-color: transparent;
    z-index: 120;
	
	span {
		@include burger(30px, 3px, 6px, darken($brand-primary, 20%));
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -15px;
		margin-top: -1.5px;
	}

	&.clicked {
		span {
		  @include burger-to-cross;
		  
		  &::before, &::after {
			background-color: $brand-primary;
		  }
		}
	}

	&:hover {
		cursor: pointer;
	}
}


/**************************************
     -   Navigation
***************************************/

nav {
	background-color: $brand-white;
	border-left: 5px solid $brand-primary;
	position: fixed;
	z-index: 110;
	width: 100%;
	top: 0;
	right: 0;
	height: 100%;
	/* box-shadow: 0 0 10px rgba( $brand-white, .3 ); */
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	-ms-transform: translateX(100%);
	-o-transform: translateX(100%);
	transform: translateX(100%);
	-webkit-transition: transform 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	-moz-transition: transform 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	-o-transition: transform 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	transition: transform 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  
	@media (max-width: 639px) {
		border-left: 0;
	}

	&.show {
		@include transform(translateX(0px));

	ul.main {
		li {
			@include transform(translateX(0px));
			opacity: 1;
        
			@for $i from 1 to 10 { 
				&:nth-child(#{$i}) {
				transition-delay: $i * 0.10s;
				}
			}
      }
    }
    
	.about, .social, ul.sub {
		@include transform(translateY(0px));
		opacity: 1;
		transition-delay: .55s;
		}
	}
  
	@media (min-width: 1200px) {
	/* padding: 110px 75px; */
	padding: 75px;
	width: 40vw;
	overflow-y: auto;
	}
  
  @media (max-width: 1199px) {
	/* padding: 110px 75px; */
	padding: 75px;
	width: 60vw;
	overflow-y: auto;
  }
  
  @media (max-width: 639px) {
    padding: 110px 30px;
	width: 100vw;
	overflow-y: auto;
  }
  
  ul.main {
    list-style-type: none;
	margin-left: 0;
    
    li {
      padding-bottom: 15px;
      @include transform(translateX(40px));
      opacity: 0;
      @include transition(all .3s ease);
      
      &:last-of-type {
        margin-bottom: 0px;
      }
      span {
		font-family: $raleway;
		font-size: .8rem;
		font-weight: 400;
		color: $brand-gray;
		margin-top: -15px;
     }
     
      a {
        color: $brand-dark-gray;
        font-family: $raleway;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.5rem;
        display: block;
        letter-spacing: .5px;
        font-weight: 600;
        padding: 10px 0;
        @include transition(all .3s ease);
        
        span {
          color: $brand-primary;
        }
        
        &:hover {
          color: $brand-primary;
		  /* text-decoration: underline; */
        }
		
      }
      a.alink {
       color: $brand-primary;
     }
   }
  }

  
  ul.sub-main {
    list-style-type: none;
	margin-left: 0;
    
    li {
      /* padding-left: 15px; */
	  padding-bottom: 0px;
      @include transform(translateX(40px));
      opacity: 0;
      @include transition(all .3s ease);
      
      &:last-of-type {
        margin-bottom: 20px;
      }
     
      a {
        color: $brand-dark-gray;
        font-family: $raleway;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.1rem;
        display: block;
        letter-spacing: .5px;
        font-weight: 400;
        padding: 0;
        @include transition(all .3s ease);
        
        span {
          color: $brand-primary;
        }
        
        &:hover {
          color: $brand-primary;
		  /* text-decoration: underline; */
        }
		
      }
      a.alink {
       color: $brand-primary;
     }
   }
  }

  
  .about {
    margin-top: 20px;
    @include transform(translateY(30px));
    opacity: 0;
    @include transition(all .4s ease);
	
	@media (max-height: 1023px) {
	display: none;
	}
    
    p {
      color: $brand-dark-gray;
      font-family: $raleway;
      font-size: .9rem;
      line-height: 1.5;
	  
	  a {
		color: $brand-primary;
		text-decoration: none;
	  }
	  a:hover {
		text-decoration: underline;
	  }
    }
  }
  
  .social {
    margin-top: 25px;
    position: relative;
    padding-bottom: 10px;
    @include transform(translateY(30px));
    opacity: 0;
    @include transition(all .4s ease);
    
    &::after {
      content: "";
      width: 230px;
	  max-width: 50%;
      height: 2px;
      background-color: $brand-primary;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    
    a {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-right: 25px;
      
      &:last-of-type {
        margin-right: 0px;
      }
      
      &:hover {
        svg {
          path, circle {
            fill: $brand-primary;
          }
        }
      }
      
      svg {
        width: 100%;
        height: 100%;
        
        path, circle {
          fill: $brand-dark-gray;
          @include transition(all .3s ease);
        }
      }
    }
  }
  
	ul.sub {
		list-style-type: none;
		margin-left: 0;
		margin-top: 40px;
		@include transform(translateY(30px));
		opacity: 0;
		@include transition(all .4s ease);

		li {
			/* margin-bottom: 10px; */

			&:last-of-type {
			margin-bottom: 0px;
			}

		a {
			color: $brand-dark-gray;
			font-family: $raleway;
			letter-spacing: 1px;
			font-size: 0.9rem;
			text-decoration: none;
			@include transition(all .3s ease);

			&:hover {
				color: $brand-primary;
				/* text-decoration: underline; */
			}
		}
		
		a.alink {
			color: $brand-primary;
			}
		}
	}
}


/**************************************
     -   Navigation - Overlay
***************************************/

.overlay {
    z-index: 100;
    transition-duration: .35s;
    content: '';
    position: fixed;
    opacity: 0;
    top: -50%;
    left: 0;
    width: 100%;
    height: 200%;
    background: $brand-black;
    -webkit-transform: rotate(30deg) translateX(150%);
    -ms-transform: rotate(30deg) translateX(150%);
    transform: rotate(30deg) translateX(150%);
 
	&.show {
		z-index: 100;
		opacity: .55;
		-webkit-transform: rotate(0deg) translateX(0);
		-ms-transform: rotate(0deg) translateX(0);
		transform: rotate(0deg) translateX(0);
	}
}