@import 'components/colors';


/**************************************
     -   Footer Test
***************************************/
.mobile-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: $brand-white;
  padding-top: 400px;
}

.action-sheet {
	background: $brand-primary;
	overflow: visible;
	width: 100%;
	left: 0;
	height: auto;
	transform: translateY(100%);
	color: $brand-white;
	padding-top: $global-padding;

	label {
		color: $brand-white;
	}
	.close-button {
		top: 0;
		font-size: 26px;
		color: darken($brand-primary, 20%);
		display: none;
	}

	&.is-open .close-button {
		display: block;
	}

	.action-sheet-inner {
		position: relative;
		padding: 0 1rem 0.5rem;
	}

	.action-sheet-toggle {
		position: absolute;
		top: - $global-padding;
		left: 50%;
		transform: translate3D(-50%, -100%, 0);
	}

	&.position-bottom.is-transition-push::after {
		box-shadow: none;
	}

	.button.white {
		border: 1px solid $brand-white;
		color: $brand-white;

		&:hover, &:active {
			  border: 1px solid $brand-white;
			  color: $brand-white;
		}
	}
}