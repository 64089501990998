/*  COLORS
========================================== */
$brand-black: #000000;
$brand-white: #ffffff;
$brand-gray: #444444;
$brand-light-gray: #f5f5f5;
$brand-middle-gray: #d1d1d1;
$brand-dark-gray: #333333;
$brand-primary: #ff3333; /* Rotton */
$brand-secondary: #3399ff; /* Blauton */
$brand-success: #33ff99; /* Grünton */
