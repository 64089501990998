@import 'components/colors';


/**************************************
     -   Marketing Ausrüstungsverleih
***************************************/
.rent-marketing {
	background-color: $brand-white;
	text-align: center;
	padding-bottom: 60px;
	padding-top: 60px;
	border-top: 1px dotted $brand-dark-gray;
	border-bottom: 1px dotted $brand-dark-gray;
	
	.marketing--card {
		position: relative;
		border: 1px solid transparent;
		
		&:hover {
			background-color: $brand-light-gray;
			border: 1px solid $brand-primary;
			
			&::after {
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				width: 0px;
				height: 0px;
				-webkit-transform:rotate(360deg);
				border-style: solid;
				border-width: 0 0 30px 30px;
				border-color: transparent transparent $brand-primary transparent;
			}
		}
	}
	
	.marketing--card-primary {
		position: relative;
		border: 1px solid transparent;
		background-color: $brand-secondary;

		> .marketing--title {
			color: $brand-white !important;
		}
		
		> .marketing--desc {
			color: $brand-white !important;
		}
	}
		
	a {
		color: $brand-primary;
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
	}
	
	.marketing--features {
		text-align: center;

		.icon {
			width: 40%;
			max-width: 160px;
			margin-bottom: 1rem;
			color: $brand-primary;
		}

		.marketing--title {
			color: $brand-primary;
			font-size: 1.6rem;
			font-weight: 600;
			padding-top: 45px;
			margin-bottom: 1rem;
		}

		.marketing--desc {
			color: $brand-dark-gray;
			font-size: .9rem;
			margin-bottom: 45px;
		}

	}
}